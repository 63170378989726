import React from "react";
import "./Maintenance.css";

import instagramWhite from "./assets/instagram-white.png";
import brandDark from "./assets/maintenance/brand-dark-resize.png";
import logoDark from "./assets/maintenance/logo-dark.png";

function Maintenance() {
  return (
    <section className="container">
      <header className="header">
        <div className="brand">
          <a href="https://armsgym.com.br/">
            <img src={brandDark} alt="Marca" className="brand-image" />
          </a>
        </div>
        <div className="social">
          <a href="https://www.instagram.com/armsgymoficial/">
            <img
              src={instagramWhite}
              alt="@armsgymoficial"
              className="instagram-logo"
            />
          </a>
        </div>
      </header>
      <main className="main">
        <img src={logoDark} alt="Logo da Marca" className="main-logo" />
        <p className="maintenance-text">EM MANUTENÇÃO</p>
      </main>
      <footer className="footer">
        <p>TODOS OS DIREITOS RESERVADOS A ARMS GYM - 2024</p>
      </footer>
    </section>
  );
}

export default Maintenance;
